import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, NProgressBarStyles, theme } from 'styles'
import { BrowserProviders } from 'providers/BrowserProviders'
import { NotificationsProvider } from 'providers/NotificationsProvider'
import { CustomQueryClientProvider } from 'providers/CustomQueryClientProvider'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import { Analytics } from '@vercel/analytics/react'
import { useCreateBrowserSupabaseClient } from 'hooks/useCreateBrowserSupabaseClient'
import { useProgressBar } from 'hooks/useProgressBar'
import { Hydrate } from '@tanstack/react-query'
import { MazeScript } from 'integrations/maze/MazeScript'

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)
  const supabaseClient = useCreateBrowserSupabaseClient()

  useProgressBar()

  return getLayout(
    <BrowserProviders>
      <ThemeProvider theme={theme}>
        <CustomQueryClientProvider>
          <Hydrate state={pageProps.dehydratedState}>
            <SessionContextProvider supabaseClient={supabaseClient} initialSession={pageProps.initialSession}>
              <NotificationsProvider>
                <GlobalStyles />
                <NProgressBarStyles />
                <MazeScript />
                <Component {...pageProps} />
                <Analytics />
              </NotificationsProvider>
            </SessionContextProvider>
          </Hydrate>
        </CustomQueryClientProvider>
      </ThemeProvider>
    </BrowserProviders>,
  )
}

import { captureException } from '@sentry/nextjs'
import { environment } from 'config'
import { isSupabasePostgrestError } from 'integrations/supabase/utils/isSupabasePostgrestError'

type Extra = unknown
type Extras = Record<string, Extra>

export async function sendExceptionToSentry(maybeError: unknown, extras?: Extras) {
  const { error, extra } = await prepareError(maybeError, extras)

  if (environment.isDevelopment) {
    // eslint-disable-next-line no-console
    console.error(error, extra)
    return
  }

  captureException(error, { extra })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function prepareError(error: any, extra?: Extras) {
  if (isSupabasePostgrestError(error)) {
    const { message, ...extras } = error
    extra = { ...extra, ...extras }
    error = new Error(message)
  }

  if (typeof error === 'string') {
    error = new Error(error)
  }

  const isRequestError = 'response' in error
  if (isRequestError) {
    extra = { ...extra, body: error.response.body, headers: error.response.headers }
  }

  const isErrorInstance = error instanceof Error
  if (!isErrorInstance) {
    extra = { ...extra, error }
    error = new Error('Non-Error thrown')
  }

  return { error, extra }
}
